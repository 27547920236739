import React from 'react';
import { styled, useThemeProps } from '@mui/material/styles';
import ErrorBoundary from '../ErrorBoundary';
import Image from '../Image';
import ArtDirectedImage from '../ArtDirectedImage';
import { sidekick } from '../../utils/sidekick';
import { MediaProps, MediaVideoProps } from './Media.types';

const Media = (inProps: MediaProps & MediaVideoProps) => {
  const props = useThemeProps({
    name: 'Media',
    props: inProps,
  });
  const { variant, file, title, fileMobile, fileTablet, testId, sidekickLookup } = props;

  // Fix a bug where sometimes Contentful assigns asset URL to a deprecated Contentful domain
  if (file && file.url) {
    file.url = file.url.replace(/^(https:)?\/\/images\.contentful\.com/, '//images.ctfassets.net');
  }

  // TODO: Add support for video
  const image = file;
  const alt = title;

  if (variant === 'embed') {
    return (
      <ErrorBoundary>
        <EmbedRoot
          {...sidekick(sidekickLookup)}
          {...(props as React.IframeHTMLAttributes<any>)}
          src={image?.url}
          sx={{ width: '100%', height: '100%', ...props.sx }}
          data-testid={testId || 'Media'}
        />
      </ErrorBoundary>
    );
  }

  if (variant === 'video') {
    return (
      <ErrorBoundary>
        <VideoRoot
          {...sidekick(sidekickLookup)}
          preload="auto"
          data-testid={testId || 'Media'}
          {...(props as MediaVideoProps)}
          sx={{ width: '100%', height: '100%', ...props.sx }}
        >
          <source src={file?.url} />
          Your browser does not support the video tag.
        </VideoRoot>
      </ErrorBoundary>
    );
  }

  if (fileTablet || fileMobile) {
    return (
      <ErrorBoundary>
        <ArtDirectedRoot
          {...sidekick(sidekickLookup)}
          {...props}
          title={title}
          file={file}
          fileTablet={fileTablet}
          fileMobile={fileMobile}
          data-testid={testId || 'Media'}
        />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Root {...sidekick(sidekickLookup)} {...props} src={image?.url} alt={alt} data-testid={testId || 'Media'} />
    </ErrorBoundary>
  );
};

// Define the pieces of the Media customizable through Theme
const Root = styled(Image, {
  name: 'Media',
  slot: 'Root',
  shouldForwardProp: (prop) => !['variant', 'fileTablet', 'fileMobile'].includes(prop as string),
  overridesResolver: (_, styles) => [styles.root],
})<{ variant?: string }>(() => ({
  // Ensure that images don't go outside of their parent container
  maxWidth: '100%',
}));

const ArtDirectedRoot = styled(ArtDirectedImage, {
  name: 'Media',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root],
})<{ variant?: string }>``;

const EmbedRoot = styled('iframe', {
  name: 'Media',
  slot: 'EmbedRoot',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root],
})<{ variant?: string }>``;

const VideoRoot = styled('video', {
  name: 'Media',
  slot: 'VideoRoot',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root],
})<{ variant?: string }>``;

export default Media;
